@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:before,
*:after {
	box-sizing: inherit;
}

*:focus:not(ol) {
	@apply outline-none ring-2 ring-red-800 ring-opacity-50;
}

html {
	height: 100%;
	box-sizing: border-box;
	touch-action: manipulation;
	font-feature-settings: "case" 1, "rlig" 1, "calt" 0;
}

html,
body {
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue",
		"Helvetica", sans-serif;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	@apply text-white bg-zinc-800 antialiased;
}

body {
	position: relative;
	min-height: 100%;
	margin: 0;
}

a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p a {
	@apply hover:underline;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.height-screen-helper {
	min-height: calc(100vh - 80px);
}

.scroll-indicator:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 20px;
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
	pointer-events: none;
}
